<template>
  <AppLoader v-if="!$store?.state?.players" />

  <hero
    :title="$t('players.title')"
    :title-color="'#fff'"
    :bg-image="'bnx_teams-bg-2-min-scaled.jpg'"
    :is-players-hero="true"
    :subtitle="$t('players.subtitle')"
  >
    <div class="header">
      <h3>{{ $t("players.section.title") }}</h3>

      <div class="filter-wrapper">
        <label for="filter">{{ $t("search_player") }}</label>
        <input
          id="filter"
          class="form-control"
          type="text"
          :placeholder="$t('search_player')"
          v-model="filters.search"
          @keyup="onChangeSearchTerm($event)"
        />
      </div>
    </div>

    <div class="player-filter-wrapper">
      <PlayerFilter
        :is-search-term="!!filters.search"
        @filters="filterPlayers($event)"
        @selectedLetterChange="sortByLetter($event)"
        @resetSearchFilter="resetSearchFilter()"
      />
    </div>

    <h3 class="title">{{ $t("players.roster.title") }} {{ filters.season - 1 }}-{{ filters.season }}</h3>

    <div class="player-list">
      <slot v-for="letter in filteredAlphabet">
        <div class="letter-section" v-if="showCurrentLetter(letter)">
          <div class="letter">{{ letter }}</div>

          <div class="grid-container">
            <slot v-for="row in filteredPlayers">
              <PlayerCard :player="row" v-if="row.player.last_name.toLowerCase().startsWith(letter.toLowerCase())" />
            </slot>
          </div>
        </div>
      </slot>

      <div class="no-result" v-if="!filteredPlayers.length">
        {{ $t("no_players") }}
      </div>
    </div>
  </hero>
</template>

<script>
import Hero from "@/components/shared/hero";
import PlayerFilter from "@/components/simple/filters/player-filter/PlayerFilter";
import PlayerCard from "@/components/simple/player/PlayerCard";
import playersService from "@/services/PlayersService";
import AppLoader from "@/components/shared/loader/AppLoader";

export default {
  name: `PlayerList`,
  data() {
    return {
      originalPlayers: [],
      filteredPlayers: [],
      filters: {},
      originalAlphabet: "abcdefghijklmnopqrstuvwxyz",
      filteredAlphabet: null,
      searchTermTimer: null,
      selectedLetter: null,
    };
  },
  components: { AppLoader, Hero, PlayerCard, PlayerFilter },
  mounted() {
    this.getPlayers(process.env.VUE_APP_ACTIVE_SEASON);
  },

  unmounted() {
    this.$store.commit("playerPageIsLoaded", false);
  },

  methods: {
    async getPlayers(season, params) {
      this.originalPlayers = await playersService.getPlayersBySeason(season, params).finally(() => {
        this.$store.commit("playerPageIsLoaded", true);
      });
      this.filteredPlayers = this.originalPlayers.sort((a, b) => (a.player.last_name > b.player.last_name ? 1 : -1));

      let playerNamesFirstLetter = this.filteredPlayers.map((player) => player.player.last_name.substring(0, 1));
      this.filteredAlphabet = [...new Set(playerNamesFirstLetter)];
    },

    onChangeSearchTerm(e) {
      if ((e.keyCode >= 37 && e.keyCode <= 40) || e.keyCode === 18) return;

      clearTimeout(this.searchTermTimer);

      if (this.filters.search?.length >= 3) {
        this.$store.commit("playerPageIsLoaded", false);
        this.searchTermTimer = setTimeout(() => {
          this.getPlayers(this.filters.season, this.filters);
          this.searchTermTimer = null;
        }, 300);
      } else if (!this.filters.search.trim()) {
        this.$store.commit("playerPageIsLoaded", false);
        this.filters.search = null;
        this.getPlayers(this.filters.season, this.filters);
      }
    },

    filterPlayers(filters) {
      this.$store.commit("playerPageIsLoaded", false);
      this.filters = { ...this.filters, ...filters };

      if (!this.searchTermTimer) {
        this.getPlayers(this.filters.season, this.filters);
      }
    },

    sortByLetter(letter) {
      this.selectedLetter = letter;
      this.filteredPlayers = letter
        ? this.originalPlayers.filter((player) => player.player.last_name?.toLowerCase().startsWith(letter))
        : this.originalPlayers;
    },

    showCurrentLetter(letter) {
      return !this.selectedLetter || this.selectedLetter.toLowerCase() === letter.toLowerCase();
    },

    resetSearchFilter() {
      this.filters = {
        search: null,
        competition_team_id: null,
        player_position_id: null,
        nationality_code: null,
        season: process.env.VUE_APP_ACTIVE_SEASON,
      };
      this.$store.commit("playerPageIsLoaded", false);
      this.sortByLetter(null);
      this.getPlayers(this.filters.season);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  h3 {
    color: #000;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
    display: inline-block;
    width: 35%;
  }

  .filter-wrapper {
    width: 375px;
  }
}

.player-filter-wrapper {
  width: 90%;
  margin-top: 20px;
}

.title {
  border: 0;
  color: #000;
  padding: 0;
  text-transform: uppercase;
  font-family: DrukWideBoldItalic, Sans-serif;
  font-weight: 600;
  font-size: 28px;
  margin-top: 50px;
}

.player-list {
  .letter-section {
    margin-bottom: 80px;

    .grid-container {
      display: grid;
      grid-column-gap: 40px;
      grid-row-gap: 60px;
      grid-template-columns: auto auto auto auto;
    }

    .letter {
      border: 0;
      color: #000;
      padding: 0;
      margin: 25px 0;
      text-transform: uppercase;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-weight: 600;
      font-size: 28px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 48%;
        width: calc(100% - 25px);
        height: 1px;
        margin-left: 25px;
        background: #6441ff;
        opacity: 0.5;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .player-filter-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 980px) {
  .player-list {
    .letter-section {
      .grid-container {
        grid-template-columns: auto auto auto;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;

    .filter-wrapper {
      width: 48%;
    }
  }

  .player-list {
    .letter-section {
      .grid-container {
        grid-template-columns: auto auto;
        grid-column-gap: 40px;
      }
    }
  }
}

@media only screen and (max-width: 639px) {
  .header {
    h3 {
      width: 100%;
    }

    .filter-wrapper {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 550px) {
  .player-list {
    .letter-section {
      .grid-container {
        grid-template-columns: auto;
        grid-column-gap: 40px;
      }
    }
  }
}
</style>
