<template>
  <div class="calendar">
    <div class="flex-container" v-for="row in schedules">
      <div class="team">
        <img
          :src="row.competitors[0].competition_team.team_logo.public_url"
          :alt="row.competitors[0].competition_team.team_logo.alt"
          class="team-img img-fluid"
        />
        <p class="team-name">{{ row.competitors[0].competition_team.name }}</p>
      </div>

      <div class="team-score">
        <div
          class="no-score"
          v-if="
            (!row?.competitors[0]?.finalScore && !row?.competitors[0]?.finalScore === 0) || row?.status !== 'finished'
          "
        ></div>
        <router-link
          :to="{
            name: misc.getRouteNameByLocale($router.options.routes, 'Game report'),
            params: { gameId: row.id },
          }"
          v-if="
            (row?.competitors[0]?.finalScore || row?.competitors[0]?.finalScore === 0) && row?.status === 'finished'
          "
        >
          <div class="score">{{ row.competitors[0].finalScore }}</div>
        </router-link>
      </div>

      <div class="details">
        <div class="date-time">
          <div class="date">{{ moment(row.game_time).format("dddd, D MMM YYYY") }}</div>
          <div class="time">{{ moment(row.game_time).format("h:mma") }}</div>
        </div>

        <div class="phase-wrapper">{{ row.phase.name }}</div>

        <div class="venue-wrapper">
          <div class="venue">{{ row.arena.name }}</div>
        </div>

        <div
          class="button-wrapper"
          v-if="row?.competitors[0]?.competition_team?.ticket_url && row?.status !== 'finished'"
        >
          <a :href="row.competitors[0].competition_team.ticket_url" class="get-tickets-btn">
            {{ $t("get_tickets") }}
          </a>
        </div>
        <div class="button-wrapper" v-if="row?.status !== 'finished'">
          <a href="https://www.bnxt.tv/browse" target="_blank" class="get-tickets-btn">
            {{ $t("watch_live") }}
          </a>
        </div>
        <div class="button-wrapper" v-if="row?.status === 'finished'">
          <a href="https://www.bnxt.tv/game-recaps" target="_blank" class="get-tickets-btn">
            {{ $t("game_report.watch_game_button") }}
          </a>
        </div>
      </div>

      <div class="team-score">
        <div
          class="no-score"
          v-if="
            (!row?.competitors[1]?.finalScore && !row?.competitors[1]?.finalScore === 0) || row?.status !== 'finished'
          "
        ></div>
        <router-link
          :to="{
            name: misc.getRouteNameByLocale($router.options.routes, 'Game report'),
            params: { gameId: row.id },
          }"
          v-if="
            (row?.competitors[1]?.finalScore || row?.competitors[1]?.finalScore === 0) && row?.status === 'finished'
          "
        >
          <div class="score">{{ row.competitors[1].finalScore }}</div>
        </router-link>
      </div>

      <div class="team">
        <img
          :src="row.competitors[1].competition_team.team_logo.public_url"
          :alt="row.competitors[1].competition_team.team_logo.alt"
          class="team-img img-fluid"
        />
        <p class="team-name">{{ row.competitors[1].competition_team.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import misc from "@/core/helpers/misc";

export default {
  name: "calendar-table",
  props: ["schedules"],
  data() {
    return {
      misc: misc,
    };
  },
  computed: { moment: () => moment },
};
</script>

<style scoped lang="scss">
.calendar {
  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    padding: 20px 0;

    .team {
      width: 20%;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }

      img {
        max-width: 60px;
      }

      &-score {
        a {
          text-decoration: none;
        }

        .score {
          font-family: DrukWide;
          font-size: 24px;
          color: #000;
        }
      }

      &-name {
        font-size: 18px;
      }
    }

    .details {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .date-time {
        font-weight: bold;
        font-size: 18px;
      }

      .venue-wrapper {
        .city {
          font-size: 14px;
        }

        .venue {
          font-size: 18px;
        }
      }

      .button-wrapper {
        margin-top: 20px;

        .get-tickets-btn {
          background-color: #6441ff;
          font-family: DrukWideItalic, Sans-serif;
          text-transform: uppercase;
          color: #fff;
          border-color: #6441ff;
          border-width: 2px;
          border-radius: 100px 100px 100px 100px;
          padding: 1px 15px;
          border-style: solid;
          text-decoration: none;
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .calendar {
    .flex-container {
      .team {
        &-score {
          width: 20px;

          .score {
            font-size: 20px;
          }

          .no-score {
            margin-top: 15px;
            margin-bottom: 32px;
            width: 100%;
            position: relative;
            height: 3px;

            &:after {
              content: "";
              position: absolute;
              right: 0;
              width: 20px;
              height: 3px;
              background: #000;
            }
          }
        }

        &-name {
          font-size: 16px;
        }
      }

      .details {
        width: 40%;

        .date-time {
          font-size: 16px;
        }

        .phase-wrapper {
          font-size: 14px;
        }

        .venue-wrapper {
          .city {
            font-size: 12px;
          }

          .venue {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .calendar {
    .flex-container {
      .team-name {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
</style>
